import React, { useState, useLayoutEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import sha256 from 'sha256';
import {
  Container, Row, Col
} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

import * as XLSX from 'xlsx';
import { Empty, Button, SkeletonComponent } from '../../../components';

import * as Helpers from '../../../helpers';

import recentIcon from '../../../assets/images/recent-icon.svg';
import arrowRight from '../../../assets/images/arrow-right.svg';
import arrowDown from '../../../assets/images/arrow-down.svg';

const LoanTable = ({
  data, getSingleLoan, cancelLoan, getUserSingleLoan,
  user: { loading },
  loanOffset,
  loanLimit,
  application_id,
}) => {
  const [dropdown, setDropdown] = useState(false);
  const [currentKey, setCurrentKey] = useState(0);
  const [show, setShow] = useState(false);
  const [selectedLoanId, setSelectedLoanId] = useState('');

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  // eslint-disable-next-line no-unused-vars
  const [winWidth, winHeight] = useWindowSize();

  const toggleMore = key => () => {
    if (key === currentKey) {
      setDropdown(!dropdown);
    } else {
      setCurrentKey(key);
      setDropdown(true);
    }
  };

  const handleSingleLoanRefresh = useCallback(loan_id => e => {
    e.stopPropagation();
    getSingleLoan(loan_id, res => {
      if (res.data.loans.length > 0) {
        Helpers.notification.success('Loan update successful.');
      } else {
        Helpers.notification.error('Loan update failed. Please try again.');
      }
    });
  }, [getSingleLoan]);

  const getLoanBreakdown = useCallback(loan_id => async e => {
    e.stopPropagation();

    const formatDateTime = dateTime => new Date(dateTime).toLocaleString();

    const formatNumber = num => (typeof num === 'number' ? num.toFixed(2) : num);

    getUserSingleLoan(loan_id, async res => {
      if (res.data.breakdown.length > 0) {
        Helpers.notification.success('Loan Breakdown fetched');

        const breakdownData = res.data.breakdown.map(item => ({
          end_of_period: formatDateTime(item.end_of_period),
          interest_at_month_beginning: formatNumber(item.interest_at_month_beginning),
          interest_at_month_end: formatNumber(item.interest_at_month_end),
          interest_overcharge: formatNumber(item.interest_overcharge),
          interest_repaid: formatNumber(item.interest_repaid),
          loan_id: item.loan_id,
          principal_at_month_beginning: formatNumber(item.principal_at_month_beginning),
          principal_at_month_end: formatNumber(item.principal_at_month_end),
          principal_repaid: formatNumber(item.principal_repaid),
          start_of_period: formatDateTime(item.start_of_period),
          tenure: item.tenure,
        }));

        const worksheet = XLSX.utils.json_to_sheet(breakdownData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Loan Breakdown');

        try {
          const fileName = `Loan Breakdown of Loan ${loan_id}.xlsx`;
          if (typeof fileName !== 'string') {
            throw new Error('Filename must be a valid string.');
          }

          await new Promise((resolve, reject) => {
            try {
              XLSX.writeFile(workbook, fileName);
              resolve();
            } catch (error) {
              reject(error);
            }
          });

          Helpers.notification.success('Loan Breakdown downloaded');
        } catch (error) {
          Helpers.notification.error('Failed to download Loan Breakdown.');
        }
      } else {
        Helpers.notification.error('Could not fetch breakdown.');
      }
    });
  }, [getUserSingleLoan]);

  const handleClose = useCallback(() => setShow(false), []);
  const handleShow = useCallback(loanId => () => {
    setShow(true);
    setSelectedLoanId(loanId);
  }, []);

  const handleSingleLoanCancel = useCallback(loan_id => e => {
    handleClose();
    e.stopPropagation();
    cancelLoan(
      loan_id,
      res => {
        getSingleLoan(loan_id);
        Helpers.notification.success(res.message);
      },
      res => {
        Helpers.notification.error(res.message);
      }
    );
  }, [cancelLoan, getSingleLoan, handleClose]);

  if (loading.some(
    url => url
        === `/loan?limit=${loanLimit}&offset=${loanOffset}&application_id=${application_id}`
      || url === `/loan?limit=${loanLimit}&offset=${loanOffset}`
  )) return <SkeletonComponent />;

  if (data.length <= 0) return <Empty />;

  return (
    <>
      <div>
        <Modal
          dialogClassName="blacklist-modal"
          show={show}
          onHide={handleClose}
                      // backdrop="static"
          keyboard={false}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="blacklist-modal-header" />
          <Modal.Body className="blacklist-modal-body">
            <p id="blackList-heading">Loan Cancellation</p>
            <p id="blackList-content">
              Are you sure you want to cancel your loan?
            </p>
            <Button size="small" background="light" onClick={handleSingleLoanCancel(selectedLoanId)}>YES</Button>
            <Button size="small" background="light" onClick={() => handleClose()}>NO</Button>
            {/* <button id="blacklist-modal-logOut">Log Out</button> */}
          </Modal.Body>
        </Modal>
      </div>
      {data.length > 0 && data.map((loan, i) => {
        const debt = Helpers.math.subtract(parseInt(loan.principal, 10), loan.totalRepaid);
        return (
          <div key={loan.loan_id}>
            {/* Web */}
            {winWidth >= 765 && (
              <div key={`web${loan.loan_id}`} className="dashboard-activity">

                <Container>
                  <Row className="align-items-center upper" onClick={toggleMore(i)}>
                    <Col xs={3} className="no-gutters-right">
                      <div className="activity-icon-item">
                        <img src={recentIcon} alt="" className="recent-icon" />
                        <div className="activity-action-items">
                          <div className="activity-default-text bold">
                            Credit
                          </div>
                          <div className="activity-default-text">
                            {loan.loanStatus === 'disbursing' ? loan.issued?.split(' ')[0] : loan[loan.loanStatus]?.split(' ')[0]}
                            {/* 2 days ago */}
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={2} className="no-gutters">
                      <div className={`activity-status ${loan.loanStatus}`}>
                        {loan.loanStatus.substr(0, 1).toUpperCase()}
                        {loan.loanStatus.substr(1)}
                      </div>
                    </Col>
                    <Col xs={3} className="no-gutters-right">
                      <div className="activity-default-text value ref">
                        REF:
                        {' '}
                        {sha256(loan.loan_id).substr(0, 8)}
                      </div>
                    </Col>
                    <Col xs={2}>
                      <div className="activity-default-text value">
                        $
                        {Helpers.formatMoney(Helpers.math.divide(loan.principal, 100))}
                      </div>
                    </Col>
                    <Col xs={1} className="no-gutters">
                      <div className="activity-action-items rep-ref">
                        <div className="activity-default-text repay">
                          {
                              debt !== 0 && (loan.loanStatus === 'disbursed' || loan.loanStatus === 'serviced')
                                ? (
                                  <Link to={{
                                    pathname: '/repay',
                                    query: {
                                      amount: 0, loanID: loan.loan_id, value: 0,
                                    },
                                  }}
                                  >
                                    <u>Repay</u>
                                  </Link>
                                ) : <div className="refresh-button">Repay</div>
                            }
                        </div>

                        <div className="activity-default-text refresh">
                          <u onClick={handleSingleLoanRefresh(loan.loan_id)}> Refresh </u>
                        </div>
                      </div>
                    </Col>
                    <Col xs={1}>
                      <div className="activity-dropdown-icon">
                        {dropdown === true && currentKey === i
                          ? <img src={arrowDown} alt="dropdown icon" onClick={toggleMore(i)} />
                          : <img src={arrowRight} alt="dropdown icon" onClick={toggleMore(i)} />}
                      </div>
                    </Col>
                  </Row>
                </Container>

                {dropdown === true && currentKey === i && (
                  <Container>
                    <Row className="align-items-center lower">
                      <Col>
                        <div className="activity-action-items">
                          <div className="activity-default-text not-black">
                            Principal
                          </div>
                          <div className="activity-default-text bold value">
                            $
                            {Helpers.formatMoney(Helpers.math.divide(loan.principal, 100))}
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="activity-action-items">
                          <div className="activity-default-text not-black">
                            Interest
                          </div>
                          <div className="activity-default-text bold value">
                            {loan.interest}
                            %
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="activity-action-items">
                          <div className="activity-default-text not-black">
                            Repaid (%)
                          </div>
                          <div className="activity-default-text bold value">
                            $
                            {Helpers.formatMoney(Helpers.math.divide(loan.totalRepaid, 100))}
                            {' '}
                            (
                            {(Helpers.math.multiply(Helpers.math.divide(loan.totalRepaid, loan.principal), 100) || 0).toFixed(2)}
                            %)
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="activity-action-items">
                          <div className="activity-default-text not-black">
                            Application
                          </div>
                          <div className="activity-default-text bold value">
                            {loan.application.name}
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row className="align-items-center lower">
                      <Col>
                        <div className="activity-action-items">
                          <div className="activity-default-text not-black">
                            Tenure
                          </div>
                          <div className="activity-default-text bold value">
                            {loan.tenure}
                            months
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="activity-action-items">
                          <div className="activity-default-text not-black">
                            Date
                          </div>
                          <div className="activity-default-text bold value">
                            {loan[loan.loanStatus]}
                          </div>
                        </div>
                      </Col>
                      <Col xs={1} className="no-gutters">
                        <div className="activity-action-items rep-ref">
                          <div className="activity-default-text refresh">
                            <u
                              onClick={getLoanBreakdown(loan.loan_id)}
                            >
                              Download Report
                            </u>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        {loan.loanStatus === 'cancelled' && (
                        <div className="activity-action-items">
                          <div className="activity-default-text not-black">
                            Cancelled Date
                          </div>
                          <div className="activity-default-text bold value">
                            {loan.cancelled}
                          </div>
                        </div>
                        )}
                      </Col>
                      <Col />
                      <Col>
                        <div className="activity-action-items">
                          <div className="activity-default-text not-black" />
                          <div className="activity-default-text bold value cancel">
                            {
                          loan.loanStatus === 'cancelled'
                            ? ''
                            : loan.loanStatus === 'requested' || loan.loanStatus === 'issued'
                              ? (
                                <u onClick={handleShow(loan.loan_id)}>
                                  Cancel
                                </u>
                              )
                              : ''
                        }
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                )}
              </div>
            )}

            {/* Mobile */}
            {winWidth < 765 && (
              <div key={`mobile${loan.loan_id}`} className="dashboard-activity">
                <div className="item level-1" onClick={toggleMore(i)}>
                  <div className="activity-icon-item">
                    <img src={recentIcon} alt="" className="recent-icon" />
                    <div className="activity-action-items">
                      <div className="activity-default-text bold">
                        Credit
                      </div>
                      <div className="activity-default-text">
                        {loan.loanStatus === 'disbursing' ? loan.issued?.split(' ')[0] : loan[loan.loanStatus]?.split(' ')[0]}
                      </div>
                    </div>
                  </div>
                  <div className="activity-value-icon">
                    <div className="activity-default-text bold value top">
                      $
                      {Helpers.formatMoney(Helpers.math.divide(loan.principal, 100))}
                    </div>
                    {dropdown === true && currentKey === i
                      ? <img src={arrowDown} alt="dropdown icon" onClick={toggleMore(i)} />
                      : <img src={arrowRight} alt="dropdown icon" onClick={toggleMore(i)} />}
                  </div>
                </div>

                {dropdown === true && currentKey === i && (
                  <>
                    <Container className="level-3-container">
                      <Row className="level-3">
                        <Col xs={6} sm={6} className="no-gutters-righ col-margin">
                          <div className={`activity-status ${loan.loanStatus}`}>
                            {loan.loanStatus.substr(0, 1).toUpperCase()}
                            {loan.loanStatus.substr(1)}
                          </div>
                        </Col>

                        <Col xs={6} sm={6} className="no-gutters-righ col-margin">
                          <div className="activity-action-items">
                            <div className="activity-default-text bold not-black">
                              Reference
                            </div>
                            <div className="activity-default-text bold value">
                              {sha256(loan.loan_id).substr(0, 8)}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} sm={6} className="no-gutters-right col-margin">
                          <div className="activity-action-items">
                            <div className="activity-default-text bold not-black">
                              Principal
                            </div>
                            <div className="activity-default-text bold value">
                              $
                              {Helpers.formatMoney(Helpers.math.divide(loan.principal, 100))}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} sm={6} className="no-gutters-right col-margin">
                          <div className="activity-action-items">
                            <div className="activity-default-text bold not-black">
                              Interest
                            </div>
                            <div className="activity-default-text bold value">
                              {loan.interest}
                              %
                            </div>
                          </div>
                        </Col>

                        <Col xs={6} sm={6} className="no-gutters-right col-margin">
                          <div className="activity-action-items">
                            <div className="activity-default-text bold not-black">
                              Repaid (%)
                            </div>
                            <div className="activity-default-text bold value">
                              $
                              {Helpers.formatMoney(Helpers.math.divide(loan.totalRepaid, 100))}
                              {' '}
                              (
                              {(Helpers.math.multiply(Helpers.math.divide(loan.totalRepaid, loan.principal), 100) || 0).toFixed(2)}
                              %)
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} sm={6} className="no-gutters-right col-margin">
                          <div className="activity-action-items">
                            <div className="activity-default-text bold not-black">
                              Application
                            </div>
                            <div className="activity-default-text bold value">
                              {loan.application.name}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} sm={6} className="no-gutters-right col-margin">
                          <div className="activity-action-items">
                            <div className="activity-default-text bold not-black">
                              Date
                            </div>
                            <div className="activity-default-text bold value">
                              {loan[loan.loanStatus]}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} sm={6} className="no-gutters-right col-margin">
                          <div className="activity-action-items">
                            <div className="activity-default-text not-black">
                              Tenure
                            </div>
                            <div className="activity-default-text bold value">
                              {loan.tenure}
                              months
                            </div>
                          </div>
                        </Col>

                        <Col xs={6} sm={6} className="no-gutters-right col-margin">
                          {loan.loanStatus === 'cancelled' && (
                          <div className="activity-action-items">
                            <div className="activity-default-text not-black">
                              Cancelled Date
                            </div>
                            <div className="activity-default-text bold value">
                              {loan.cancelled}
                            </div>
                          </div>
                          )}
                        </Col>
                        <Col xs={6} sm={6} className="no-gutters-right col-margin" />

                      </Row>
                    </Container>

                    <div className="item level-4">
                      <div className="activity-default-text bold repay">
                        {
                    debt !== 0 && (loan.loanStatus === 'disbursed' || loan.loanStatus === 'serviced')
                      ? (
                        <Link to={{
                          pathname: '/repay',
                          query: {
                            amount: 0, loanID: loan.loan_id, value: 0,
                          },
                        }}
                        >
                          <u>Repay</u>
                        </Link>
                      ) : <div className="refresh-button">Repay</div>
                  }
                      </div>
                      <div className="vertical-line" />
                      <div className="activity-default-text bold refresh">
                        <u onClick={handleSingleLoanRefresh(loan.loan_id)}>
                          Refresh
                        </u>
                      </div>
                      <div className="vertical-line" />
                      <div className="activity-default-text bold cancel">
                        {
                        loan.loanStatus === 'requested' || loan.loanStatus === 'issued'
                          ? (
                            <u onClick={handleShow(loan.loan_id)}>
                              Cancel
                            </u>
                          )
                          : <div className="refresh-button">Cancel</div>
                      }
                      </div>
                    </div>
                  </>
                )}

              </div>
            )}
          </div>
        );
      })}
    </>
  );
};

export default LoanTable;
